import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import { card, image, ratio, heading, text, button } from './link-card.module.scss';
import { INavLink } from '../../models/nav-link.model';
import useTranslations from '../../hooks/use-translations';

import Button from '../atoms/button';
import ConditionalLink from '../hoc/conditional-link';

interface ICategoryCardProps {
    className?: string;
    NameTag?: React.ElementType;
    link: INavLink;
}

const LinkCard: React.FC<ICategoryCardProps> = ({ className = '', NameTag = 'h2', link }) => {
    const { type, value, label, description, media, openInNewTabFlag } = link;
    const t = useTranslations('LinkCard');

    return (
        <ConditionalLink
            type={type}
            className={`${className} ${card} link-card`}
            to={value}
            blank={openInNewTabFlag}
        >
            <Image className={`${image} link-card__image`} media={media} ratioClass={ratio} />
            <NameTag className={`${heading} link-card__name`}>{label}</NameTag>
            <Button className={`${button} link-card__button`} as="element" context="linkCard">
                {t.button}
            </Button>
            {description && <p className={`${text} link-card__short-description`}>{description}</p>}
        </ConditionalLink>
    );
};

export default LinkCard;
