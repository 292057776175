import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    sectionContainer,
    grid,
    titleText,
    contentText,
    button,
    image,
    ratio,
} from './cta.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

interface ITextProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

const Cta: React.FC<ITextProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        content: { texts, media, buttons },
        css,
        style,
    } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionContainer}`}
            classes={{
                container: grid,
                title: titleText,
                description: contentText,
                button: button,
            }}
            title={texts[0]}
            description={texts[1]}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
            css={css}
            style={style}
            TitleTag={TitleTag}
        >
            <Image ratioClass={ratio} className={image} media={media} />
        </Section>
    );
};

export default Cta;

/*TODO:
    - Dodac poprwany komponent zdjecia
    - Zmienic nazwe na lepsza
    - Sprawdzic czego brakuje
*/
